<template>
  <section class="my-5">
    <div class="mb-5 text-center">
      <h1>Edit Response</h1>
    </div>
    <EditResponseEntryForm />
  </section>
</template>
    
    <script lang="ts">
import { defineComponent } from "vue";
import EditResponseEntryForm from "@/views/settings/constituent/EditResponseEntryForm.vue";
export default defineComponent({
  name: "EditResponseEntry",
  components: {
    EditResponseEntryForm,
  },
  setup() {
    return {};
  },
});
</script>
    
    <style scoped></style>
    