
import { defineComponent } from "vue";
import EditResponseEntryForm from "@/views/settings/constituent/EditResponseEntryForm.vue";
export default defineComponent({
  name: "EditResponseEntry",
  components: {
    EditResponseEntryForm,
  },
  setup() {
    return {};
  },
});
